exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-blog-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/blog-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-blog-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-homepage-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/homepage-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-homepage-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-content-pages-about-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/page-query.tsx?__contentFilePath=/vercel/path0/content/pages/about/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-content-pages-about-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-aprende-a-programar-ya-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/vercel/path0/content/posts/aprende-a-programar-ya/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-aprende-a-programar-ya-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-descubre-el-superpoder-de-crear-paginas-web-tu-aventura-con-html-css-y-java-script-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/vercel/path0/content/posts/descubre-el-superpoder-de-crear-paginas-web-tu-aventura-con-html-css-y-javaScript/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-descubre-el-superpoder-de-crear-paginas-web-tu-aventura-con-html-css-y-java-script-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-estructura-de-una-pagina-web-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/vercel/path0/content/posts/estructura-de-una-pagina-web/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-estructura-de-una-pagina-web-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-introduccion-a-css-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/vercel/path0/content/posts/introduccion-a-css/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-introduccion-a-css-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-introduccion-al-desarrollo-web-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/vercel/path0/content/posts/introduccion-al-desarrollo-web/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-introduccion-al-desarrollo-web-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tag-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/tag-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tag-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tags-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/tags-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tags-query-tsx" */)
}

